// @ts-ignore
import yaml from "../../data/seo-strategy-for-marketing.yaml"
import React from "react"
import { validatePageYamlData } from "../../helpers/prop-types"
import Header from "../../components/sections/header"
import { graphql } from "gatsby"
import { SectionBox } from "../../components/sections/section-box"
import TitleWithUnderline from "../../components/unitary/title-with-underline"
import { PORTRAIT_BODY_TEXT_ALIGNMNENTS } from "../../components/child-mappable/portrait"
import Landscape from "../../components/h2nits/landscape"
import { SectionTileAncestor } from "../../components/sections/section-tile-ancestor"
import TileParentChildBox from "../../components/layout/tile-parent-child-box"
import Content from "../../components/unitary/content"
import { CaptionedImage } from "../../components/captioned-image"
import LayoutContainer from "../../components/layout/layout-container"
import { PortraitArrayWithTitle } from "../../components/sections/portrait-array-with-title"

class SeoStrategyForMarketing extends React.Component {
  constructor(props) {
    super()
  }

  render() {
    validatePageYamlData(yaml)

    const images = this.props.data

    return (
      <LayoutContainer>
        <Header {...yaml.header} />
        <SectionBox>
          <Landscape
            data={yaml.introduction}
            titleStyle="chevron"
            images={images}
          />
        </SectionBox>
        <PortraitArrayWithTitle
          data={yaml.understand_topics}
          images={images}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
        <SectionTileAncestor>
          <TileParentChildBox>
            <TitleWithUnderline title={yaml.find_segments.title} />
            <Content body={yaml.find_segments.body} />
          </TileParentChildBox>
          <TileParentChildBox>
            <TitleWithUnderline title={yaml.good_articles.title} />
            <Content body={yaml.good_articles.body} />
            <CaptionedImage data={yaml.good_articles.image} images={images} />
          </TileParentChildBox>
        </SectionTileAncestor>
        <PortraitArrayWithTitle
          data={yaml.get_links}
          images={images}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
        <SectionTileAncestor>
          <TileParentChildBox>
            <TitleWithUnderline title={yaml.on_page_seo.title} />
            <Content body={yaml.on_page_seo.body} />
          </TileParentChildBox>
          <TileParentChildBox>
            <TitleWithUnderline title={yaml.track_performance.title} />
            <Content body={yaml.track_performance.body} />
            <CaptionedImage
              data={yaml.track_performance.image}
              images={images}
            />
          </TileParentChildBox>
        </SectionTileAncestor>
      </LayoutContainer>
    )
  }
}

export default SeoStrategyForMarketing

export const query = graphql`
  query {
    google_my_business_icons: file(
      relativePath: {
        eq: "seo-strategy-for-marketing/google_my_business_icons.png"
      }
    ) {
      ...fixedWidth340
    }
    thumbs_up: file(
      relativePath: { eq: "seo-strategy-for-marketing/thumbs_up.png" }
    ) {
      ...fixedWidth90
    }
    serp_watcher: file(
      relativePath: { eq: "seo-strategy-for-marketing/serp_watcher.png" }
    ) {
      ...fixedWidthFiveColumns
    }
  }
`
