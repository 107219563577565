import React from "react"
import { generateImage } from "./factories/image-factory"
import Column from "./layout/column"
import Columns from "./layout/columns"
import Content from "./unitary/content"

export function CaptionedImage(props) {
  return (
    <Columns classes={["is-centered", "is-mobile"]}>
      <Column classes={["is-three-quarters"]}>
        <div className="media is-align-items-center">
          <figure className="media-left">
            <div>{generateImage(props.images, props.data)}</div>
          </figure>
          <div className="media-content">
            <div className="content">
              <Content
                classes={["has-text-weight-light"]}
                body={props.data.caption}
              />
            </div>
          </div>
        </div>
      </Column>
    </Columns>
  )
}
